import React, { useEffect, useState } from "react";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../../feature-flags/flags";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../../providers/UserProvider";
import { ADMINCENTER_GROUP_NAME, ALL_PAGES_SIZE, DEFAULT_PAGE_SIZE } from "../../../../constants/constants";
import { useInvitations } from "../../../../hooks/useInvitations/useInvitations";
import { Button, PaginationControls } from "@optimizely/axiom";
import { Sidebar } from "../../../components/Sidebar/Sidebar";
import { SupportAccessForm } from "../../../components/SupportAccessForm/SupportAccessForm";
import { Invitation } from "../../../../domain/Invitation";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog/ConfirmationDialog";
import { datadogRum } from "@datadog/browser-rum";
import { useUserGroups } from "../../../../hooks/useUserGroups/useUserGroups";
import { useUsers } from "../../../../hooks/useUsers/useUsers";
import { emitToast } from "../../../../lib/toaster-utils";
import { IUserInstancePermission } from "../../../../domain/UserInstancePermission";
import { DetailedSupportTable } from "../../../components/SupportTable/DetailedSupportTable";
import { SearchInput } from "../../../components/SearchInput/SearchInput";
import styles from "./SupportAccess.module.scss";
import { ResetFilterPrompt } from "../../../components/ResetFilterPrompt/ResetFilterPrompt";

export interface SupportOrganizationPermissions {
    relatedInvitations: Invitation[];
    instancePermissions: IUserInstancePermission[];
    revokeDate: Date | undefined;
    organizationId: string;
    organizationName: string;
}
interface CsmOrgsVariable {
    organizations: [
        {
            id: string;
            name: string;
        }
    ];
}

export const SupportAccess = () => {
    const { profile, instancePermissions, refreshPermissions } = useUserContext();
    const [targetOrgId, setTargetOrgId] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const { userGroups: targetOrgUserGroups = [], isLoading: userGroupsLoading } = useUserGroups({
        organizationId: targetOrgId,
        query: ADMINCENTER_GROUP_NAME,
        useExactMatchSearch: true,
        pageSize: ALL_PAGES_SIZE
    });
    const { enabled: showSupportPage } = useFeatureFlag(Flags.ENABLE_SUPPORT_FEATURES);
    const { enabled: hideCsmOrgs, variables } = useFeatureFlag(Flags.HIDE_SUPPORT_ORGANIZATIONS);
    const [showForm, setShowForm] = useState(false);
    const [orgPermissionToRevoke, setOrgPermissionToRevoke] = useState<SupportOrganizationPermissions | null>(null);
    const [removalError, setRemovalError] = useState<string | null>(null);
    const [removalInProgress, setRemovalInProgress] = useState(false);
    const [sidebarInvitation, setSidebarInvitation] = useState<Invitation | null>(null);
    const navigate = useNavigate();
    const { removeUser } = useUsers({ organizationId: orgPermissionToRevoke?.organizationId });
    const [searchQuery, setSearchQuery] = useState<string>("");

    const { invitations, isLoading, createInvitation, mutate } = useInvitations({
        createdBy: profile?.email,
        inviteeEmail: profile?.email,
        pageSize: ALL_PAGES_SIZE
    });

    const handlePagination = (page: number) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        if (!showSupportPage) {
            navigate("/404");
        }
    }, [navigate, showSupportPage]);

    useEffect(() => {
        setCurrentPage(1);
    }, [searchQuery, setCurrentPage]);

    const csmOrgs: CsmOrgsVariable = variables.csm_orgs as CsmOrgsVariable;
    const csmOrgIds = hideCsmOrgs ? csmOrgs.organizations?.map((o) => o.id) : [];

    const organizationPermissions: SupportOrganizationPermissions[] | undefined =
        invitations
            ?.filter((i) => !!i.acceptedDate)
            .filter((i) => !csmOrgIds.includes(i.organizationId))
            .reduce((list: SupportOrganizationPermissions[], invitation) => {
                const existingOrgPermission = list.find((op) => op.organizationId === invitation.organizationId);

                if (existingOrgPermission) {
                    existingOrgPermission.relatedInvitations.push(invitation);

                    if (invitation.acceptedDate) {
                        const revokeDate = new Date(
                            invitation.acceptedDate.setDate(invitation.acceptedDate.getDate() + 5)
                        );
                        if (existingOrgPermission?.revokeDate && revokeDate > existingOrgPermission.revokeDate) {
                            existingOrgPermission.revokeDate = revokeDate;
                        }
                    }
                } else {
                    list.push({
                        revokeDate: invitation.acceptedDate
                            ? new Date(invitation.acceptedDate.setDate(invitation.acceptedDate.getDate() + 5))
                            : undefined,
                        relatedInvitations: [invitation],
                        instancePermissions:
                            instancePermissions?.filter((ip) => ip.organizationId === invitation.organizationId) || [],
                        organizationId: invitation.organizationId,
                        organizationName: invitation.organizationName || ""
                    });
                }

                return list;
            }, [])
            .filter(
                (op) =>
                    op.instancePermissions.length > 0 &&
                    (!searchQuery || op.organizationName.toLowerCase().includes(searchQuery.toLowerCase()))
            ) || [];

    const totalPages = Math.ceil(organizationPermissions.length / DEFAULT_PAGE_SIZE);
    const showPagination = organizationPermissions.length > DEFAULT_PAGE_SIZE;

    const start = (currentPage - 1) * DEFAULT_PAGE_SIZE;
    const end = start + DEFAULT_PAGE_SIZE;
    const currentPagePermissions = organizationPermissions.slice(start, end);

    const handleSupportInvitation = async ({ reasonForAccess }: { reasonForAccess?: string }) => {
        const [targetOrgAdminGroup] = targetOrgUserGroups;
        await createInvitation({
            createdBy: profile!.email,
            email: profile!.email,
            firstName: profile!.firstName,
            lastName: profile!.lastName,
            reasonForAccess,
            organizationId: targetOrgId,
            requireAcceptance: false,
            userGroups: targetOrgAdminGroup ? [{ id: targetOrgAdminGroup.id, name: targetOrgAdminGroup.name }] : []
        });

        await refreshPermissions();
    };

    const handleConfirmation = async () => {
        setRemovalInProgress(true);

        !!profile &&
            removeUser({ user: profile })
                .then(() => {
                    emitToast({ message: "Organization access successfully removed." });
                    const refetchEvent = new Event("@opti-product-switcher:refetch");
                    window.dispatchEvent(refetchEvent);

                    refreshPermissions().then(() => {
                        mutate().then(() => {
                            setRemovalError(null);
                            setOrgPermissionToRevoke(null);
                        });
                        setSidebarInvitation(null);
                    });
                })
                .catch((error) => {
                    console.error(error);
                    datadogRum.addError(error);
                    setRemovalError(
                        error[0]?.message ||
                            "Unable to remove organization access at this time. Please try again later or contact your admin."
                    );
                })
                .finally(() => {
                    setRemovalInProgress(false);
                });
    };

    const closeSidebar = () => {
        setTargetOrgId("");
        setShowForm(false);
        setSidebarInvitation(null);
    };

    const handleRemoval = ({ orgPermission }: { orgPermission: SupportOrganizationPermissions }) => {
        setOrgPermissionToRevoke(orgPermission);
    };

    const handleCancel = () => {
        setRemovalError(null);
        setOrgPermissionToRevoke(null);
        setSidebarInvitation(null);
    };

    return (
        <>
            <div className="flex flex--row flex-justified--between push-triple--ends">
                <div className="width--1-4">
                    <SearchInput
                        placeholder="Search organizations by name..."
                        type="text"
                        className={styles["support-access__search"]}
                        value={searchQuery}
                        onChange={({ value }) => {
                            setSearchQuery(value);
                        }}
                    />
                </div>
                <div>
                    <Button
                        style="highlight" //eslint-disable-line react/style-prop-object
                        onClick={() => {
                            setShowForm(true);
                        }}
                    >
                        Add organization access...
                    </Button>
                </div>
            </div>
            {(showForm || !!sidebarInvitation) && (
                <Sidebar
                    heading={showForm ? "Add Organization Access" : "Organization Access"}
                    isOpen
                    onCancel={closeSidebar}
                >
                    <SupportAccessForm
                        invitation={sidebarInvitation}
                        onClose={closeSidebar}
                        onOrgSelect={setTargetOrgId}
                        onSubmit={handleSupportInvitation}
                        isLoading={userGroupsLoading}
                    />
                </Sidebar>
            )}
            {searchQuery.length > 2 && !organizationPermissions?.length && (
                <ResetFilterPrompt onClick={() => setSearchQuery("")} prompt="No organizations match your filter." />
            )}
            {organizationPermissions.length > 0 && (
                <DetailedSupportTable
                    orgPermissions={currentPagePermissions}
                    loading={isLoading}
                    onRemove={handleRemoval}
                />
            )}
            {showPagination && (
                <PaginationControls
                    className="anchor--bottom"
                    currentPage={currentPage}
                    goToPage={handlePagination}
                    totalPages={totalPages}
                />
            )}
            {!!orgPermissionToRevoke && (
                <ConfirmationDialog
                    onCancel={handleCancel}
                    onConfirmation={handleConfirmation}
                    loading={removalInProgress}
                    error={removalError}
                    title="Remove Organization Access"
                    callToActionText="Remove access..."
                >
                    <div key={1}>
                        Revoking access to this organization means you will be removed from the organization and unable
                        to enact changes or offer support to customers.
                    </div>
                </ConfirmationDialog>
            )}
        </>
    );
};

SupportAccess.displayName = "SupportAccess";
