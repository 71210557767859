import { UserGroup as RestUserGroup } from "../rest-model/UserGroup";
import { User as RestUser } from "../rest-model/User";
import { ProductInstance as RestProductInstance } from "../rest-model/ProductInstance";
import { IdentityProvider as RestIdentityProvider } from "../rest-model/IdentityProvider";
import { OrganizationProductInstances as RestOrganizationProductInstances } from "../rest-model/OrganizationProductInstances";
import { UserInstancePermission as RestUserInstancePermission } from "../rest-model/UserInstancePermission";
import { Role as RestRole } from "../rest-model/Role";
import { Organization as RestOrganization } from "../rest-model/Organization";
import { ProductAttribute as RestAttribute } from "../rest-model/ProductAttribute";
import { Collection } from "../domain/Collection";
import {
    ADMINCENTER_PRODUCT_ID,
    ALL,
    ALL_PAGES_SIZE,
    CUSTOMER_DEFINED_INSTANCE_ROLE,
    CUSTOMER_DEFINED_PRODUCT_ROLE,
    DEFAULT_PAGE_SIZE,
    GROUP_TYPES
} from "../constants/constants";
import { RoleCreationParameters } from "../domain/Role";
import { InstancePermission } from "../domain/InstancePermission";
import { GetAttributeParameters } from "../rest-model/GetAttributeParameters";
import { Invitation as RestInvitation } from "../rest-model/Invitation";
import { IOrganizationProductInstance as RestOrganizationProduct } from "../rest-model/OrganizationProductInstances";
import { Customer as RestCustomer } from "../rest-model/Customer";
import { BillingNotification } from "../rest-model/BillingNotification";
import { LocalLoginSettings } from "../rest-model/LocalLoginSettings";
import { OrganizationSettings as RestOrganizationSettings } from "../rest-model/OrganizationSettings";

interface IRestResponseResult<T> {
    items: T[];
    totalItemCount: number;
    links: ILink[];
}

interface ILink {
    rel: string;
    href: string;
}

export interface IIdentityRepository {
    getUserGroups({
        organizationId,
        query,
        emails,
        groupTypes,
        size,
        page,
        roleIds,
        attributeIds
    }: {
        organizationId: string | null;
        query?: string;
        emails?: string[];
        groupTypes?: string[];
        size?: number;
        page?: number;
        roleIds?: string[];
        attributeIds?: string[];
    }): Promise<Collection<RestUserGroup>>;

    getUsers({
        organizationId,
        query,
        email,
        userGroupIds,
        includeExternalStatus,
        size,
        page
    }: {
        organizationId?: string;
        query?: string;
        email?: string;
        userGroupIds?: string[] | null;
        includeExternalStatus?: boolean;
        size?: number;
        page?: number;
    }): Promise<Collection<RestUser>>;

    getUsersByPermission({
        organizationId,
        productIds,
        instanceIds,
        attributeIds,
        roleIdFilters,
        query,
        includeExternalStatus,
        size,
        page
    }: {
        organizationId: string;
        productIds?: string[];
        instanceIds?: string[];
        attributeIds?: string[];
        roleIdFilters?: string[];
        query?: string;
        includeExternalStatus?: boolean;
        size?: number;
        page?: number;
    }): Promise<Collection<RestUser>>;

    getUserByEmail(email: string, includeExternalStatus?: boolean): Promise<RestUser>;

    deleteUser({ email }: { email: string }): Promise<void>;

    unlockUser(email: string): Promise<void>;

    activateUser(email: string): Promise<void>;

    addUserGroup(userGroup: RestUserGroup): Promise<RestUserGroup>;

    deleteUserGroup({ userGroupId }: { userGroupId: string }): Promise<void>;

    updateUserGroup(userGroup: RestUserGroup): Promise<RestUserGroup>;

    addUserGroupUsers({
        userGroupId,
        userEmails
    }: {
        userGroupId: string;
        userEmails: string[];
    }): Promise<RestUserGroup>;

    addUserGroupProducts({
        userGroupId,
        instancePermissions
    }: {
        userGroupId: string;
        instancePermissions: InstancePermission[];
    }): Promise<RestUserGroup>;

    addUserGroupsToUser({ email, userGroupIds }: { email: string; userGroupIds: string[] }): Promise<RestUser>;

    deleteUserGroupUsers({ userGroupId, userEmails }: { userGroupId: string; userEmails: string[] }): Promise<void>;

    deleteUserGroupProducts({
        userGroupId,
        instanceIds
    }: {
        userGroupId: string;
        instanceIds: string[];
    }): Promise<RestUserGroup>;

    addUser(user: RestUser): Promise<RestUser>;

    getUserGroupInstances({ userGroupId }: { userGroupId: string }): Promise<Collection<RestProductInstance>>;

    getOrganizationProductInstances({
        organizationId
    }: {
        organizationId: string;
    }): Promise<RestOrganizationProductInstances>;

    getUserGroupByName({
        organizationId,
        name
    }: {
        organizationId: string;
        name: string;
    }): Promise<RestUserGroup | undefined>;

    getUserGroupInstance({
        instanceId,
        userGroupId
    }: {
        instanceId: string;
        userGroupId: string;
    }): Promise<RestProductInstance>;

    // this actually replaces the user...
    updateUser(user: RestUser): Promise<RestUser>;

    updateUserFields(email: string, ...fields: any): Promise<RestUser>;

    addOrganizationIdentityProvider({
        organizationId,
        issuer,
        ssoUrl,
        certificate
    }: {
        organizationId: string;
        issuer: string;
        ssoUrl: string;
        certificate: File;
    }): Promise<RestIdentityProvider>;

    addSAMLIdentityProvider({
        idpName,
        organizationId,
        issuer,
        ssoUrl,
        certificate
    }: {
        idpName: string;
        organizationId: string;
        issuer: string;
        ssoUrl: string;
        certificate: File;
    }): Promise<RestIdentityProvider>;

    addOIDCIdentityProvider({
        authorizationUrl,
        clientId,
        clientSecret,
        idpName,
        issuerUrl,
        jwksUrl,
        organizationId,
        provider,
        tokenUrl,
        userInfoUrl,
        wellKnownUrl
    }: {
        authorizationUrl?: string;
        clientId: string;
        clientSecret: string;
        idpName: string;
        issuerUrl?: string;
        jwksUrl?: string;
        organizationId: string;
        provider: string;
        tokenUrl?: string;
        userInfoUrl?: string;
        wellKnownUrl?: string;
    }): Promise<RestIdentityProvider>;

    deleteOrganizationIdentityProvider({ id, organizationId }: { id: string; organizationId: string }): Promise<any>;

    deleteOrganizationIdentityProviders({ organizationId }: { organizationId: string }): Promise<any>;

    getOrganizationIdentityProviders({
        organizationId
    }: {
        organizationId: string;
    }): Promise<RestIdentityProvider[] | null>;

    getOrganizationDomains({ organizationId }: { organizationId: string }): Promise<string[]>;

    updateOrganization({ organizationId, mfaEnabled }: { organizationId: string; mfaEnabled?: boolean }): Promise<any>;

    updateOrganizationDomains({
        organizationId,
        domains
    }: {
        organizationId: string;
        domains: string[];
    }): Promise<string[]>;

    getUserAdminCenterInstancePermissions({
        email,
        orgId
    }: {
        email: string;
        orgId?: string;
    }): Promise<RestUserInstancePermission[]>;

    getUserInstancePermissions({ email }: { email: string }): Promise<RestUserInstancePermission[]>;

    getRoles({
        organizationId,
        productId,
        instanceId,
        size,
        page
    }: {
        organizationId: string;
        productId?: string;
        instanceId?: string;
        size?: number;
        page?: number;
    }): Promise<Collection<RestRole>>;

    createRole(roleDetails: RoleCreationParameters): Promise<RestRole>;
    deleteRole({ roleId }: { roleId: string }): Promise<void>;
    updateRole(roleDetails: RoleCreationParameters): Promise<RestRole>;

    getAttributes(parameters: GetAttributeParameters): Promise<Collection<RestAttribute>>;

    getAttribute(id: string): Promise<RestAttribute>;

    getInvitations({
        createdBy,
        inviteeEmail,
        organizationId,
        size,
        page
    }: {
        inviteeEmail?: string;
        createdBy?: string;
        organizationId?: string;
        size?: number;
        page?: number;
    }): Promise<Collection<RestInvitation>>;

    createInvitation(invitation: RestInvitation): Promise<RestInvitation>;

    revokeInvitation(invitationId: string, requireAcceptance: boolean): Promise<void>;

    resendInvitation(invitationId: string): Promise<void>;

    updateInstance({ instance }: { instance: Partial<RestOrganizationProduct> }): Promise<RestProductInstance>;

    searchCustomers({ query }: { query: string }): Promise<Collection<RestCustomer>>;

    getOrganization({ id }: { id: string }): Promise<RestOrganization>;

    getProviders(): Promise<string[]>;

    getUserListByProduct({
        organizationId,
        page,
        productIds,
        searchQuery
    }: {
        organizationId: string;
        page?: number;
        productIds: string[];
        searchQuery?: string;
    }): Promise<Collection<RestUser>>;

    getUserListByInstance({
        attributeIds = [],
        instanceIds,
        organizationId,
        page = 1,
        roleIds = [],
        searchQuery = ""
    }: {
        attributeIds?: string[];
        instanceIds: string[];
        organizationId?: string | undefined;
        page?: number;
        roleIds?: string[];
        searchQuery?: string;
    }): Promise<Collection<RestUser>>;

    getUserListByProject({
        attributeIds,
        organizationId,
        roleIds,
        page,
        searchQuery
    }: {
        organizationId: string;
        attributeIds: string[];
        roleIds?: string[];
        page?: number;
        searchQuery?: string;
    }): Promise<Collection<RestUser>>;

    getUserGroupsByInstance({
        attributeIds = [],
        groupTypes = [],
        instanceIds,
        organizationId,
        roleIds = []
    }: {
        attributeIds?: string[];
        groupTypes?: string[];
        instanceIds: string[];
        organizationId?: string | undefined;
        roleIds?: string[];
    }): Promise<Collection<RestUserGroup>>;

    getUserGroupsByAttributeId({
        organizationId,
        attributeIds,
        groupTypes
    }: {
        organizationId: string;
        attributeIds: string[];
        groupTypes?: string[];
    }): Promise<Collection<RestUserGroup>>;

    migrateUser({ email, organizationId }: { email: string; organizationId: string }): Promise<RestUser>;

    updateIdentityProviderDomains({
        organizationId,
        identityProviderId,
        domains
    }: {
        organizationId: string;
        identityProviderId: string;
        domains: string[];
    }): Promise<RestIdentityProvider>;

    getBillingNotifications({
        organizationId
    }: {
        organizationId: string;
    }): Promise<{ Settings: { billingNotifications: BillingNotification[] } }>;
    updateBillingNotification({
        notificationId,
        organizationId,
        updates
    }: {
        notificationId: string;
        organizationId: string;
        updates: Pick<BillingNotification, "enabled" | "recipients">;
    }): Promise<BillingNotification>;

    getLocalLoginSettings({ organizationId }: { organizationId: string }): Promise<{ Settings: LocalLoginSettings }>;

    getOrganizationSettings({
        organizationId
    }: {
        organizationId: string;
    }): Promise<{ Settings: RestOrganizationSettings }>;

    updateGenAISettings({
        organizationId,
        settings
    }: {
        organizationId: string;
        settings: { enabled: boolean };
    }): Promise<{ enabled: boolean }>;

    updateLocalLoginSettings({
        organizationId,
        settings
    }: {
        organizationId: string;
        settings: { enabled: boolean; mfaEnabled: boolean };
    }): Promise<LocalLoginSettings>;

    updateOrganizationSettings({
        organizationId,
        updates
    }: {
        organizationId: string;
        updates: Partial<RestOrganizationSettings>;
    }): Promise<{ Settings: RestOrganizationSettings }>;
}

export class IdentityRepository implements IIdentityRepository {
    baseURL;
    accessToken: string;

    constructor(baseURL = "/", accessToken: string) {
        this.baseURL = baseURL;
        this.accessToken = accessToken;
    }

    private async request<T>(
        method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
        url: string,
        body?: object,
        retryCount?: number
    ): Promise<T> {
        const response = await fetch(`${this.baseURL}${url}`, {
            method,
            headers: {
                Authorization: `Bearer ${this.accessToken}`,
                "Content-Type": "application/json"
            },
            body: body ? JSON.stringify(body) : null
        });

        //check response.text() first in case it is empty, which causes an error when using response.json()
        const responseText: string = await response.text();

        if (responseText && !response.ok) {
            throw JSON.parse(responseText);
        }

        if (!response.ok) {
            if (retryCount && response.status === 500) {
                return this.request<T>(method, url, body, retryCount - 1);
            } else {
                throw new Error(`Failed request: (${response.status})`);
            }
        }

        const data: T = responseText === "" ? undefined : JSON.parse(responseText);

        return data;
    }

    public async getUserGroups({
        organizationId,
        emails,
        groupTypes = [],
        query,
        size = 20,
        page = 1,
        roleIds = [],
        attributeIds = []
    }: {
        organizationId: string | null;
        query?: string;
        emails?: string[];
        groupTypes?: string[];
        size?: number;
        page?: number;
        roleIds?: string[];
        attributeIds?: string[];
    }): Promise<Collection<RestUserGroup>> {
        const search = new URLSearchParams();
        search.append("organizationId", organizationId || "");
        search.append("size", `${size}`);
        search.append("offset", `${(page - 1) * size}`);
        emails?.forEach((email) => {
            search.append("users", email);
        });

        groupTypes?.forEach((type) => {
            search.append("groupTypes", type);
        });

        roleIds?.forEach((roleId) => {
            search.append("roleIds", roleId);
        });
        attributeIds?.forEach((attId) => {
            search.append("attributeIds", attId);
        });

        if (query) {
            search.append("name", query.toLowerCase());
            search.append("description", query.toLowerCase());
        }

        const userGroups = await this.request<IRestResponseResult<RestUserGroup>>(
            "GET",
            `/api/usergroups?${search.toString()}`
        );

        return new Collection<RestUserGroup>({
            items: userGroups.items.map((userGroup: RestUserGroup) => new RestUserGroup(userGroup)),
            totalCount: userGroups.totalItemCount
        });
    }

    public async deleteUser({ email }: { email: string }): Promise<void> {
        return await this.request<undefined>("DELETE", `/api/users/${email}`);
    }

    public async unlockUser(email: string): Promise<void> {
        return await this.request<undefined>("POST", `/api/users/${email}/unlock`);
    }

    public async activateUser(email: string): Promise<void> {
        return await this.request<undefined>("POST", `/api/users/${email}/activate`);
    }

    public async addUserGroup(userGroup: RestUserGroup): Promise<RestUserGroup> {
        const userGroupResponse = await this.request<RestUserGroup>("POST", "/api/usergroups", userGroup);

        return new RestUserGroup(userGroupResponse);
    }

    public async deleteUserGroup({ userGroupId }: { userGroupId: string }): Promise<void> {
        return await this.request<undefined>("DELETE", `/api/usergroups/${userGroupId}`);
    }

    public async addUserGroupUsers({
        userGroupId,
        userEmails
    }: {
        userGroupId: string;
        userEmails: string[];
    }): Promise<RestUserGroup> {
        const userGroup = await this.request<IRestResponseResult<RestUserGroup>>(
            "POST",
            `/api/usergroups/${userGroupId}/users`,
            { userEmails },
            3
        );

        return new RestUserGroup(userGroup);
    }

    public async addUserGroupsToUser({
        email,
        userGroupIds
    }: {
        email: string;
        userGroupIds: string[];
    }): Promise<RestUser> {
        const user = await this.request<IRestResponseResult<RestUser>>(
            "POST",
            `/api/users/${email}/usergroups`,
            { userGroupIds },
            3
        );

        return new RestUser(user);
    }

    public async addUserGroupProducts({
        userGroupId,
        instancePermissions
    }: {
        userGroupId: string;
        instancePermissions: InstancePermission[];
    }): Promise<RestUserGroup> {
        const [userGroup] = await Promise.all(
            instancePermissions.map((permission) => {
                return this.request<IRestResponseResult<RestUserGroup>>(
                    "POST",
                    `/api/usergroups/${userGroupId}/instancepermissions/${permission.instanceId}`,
                    { roleIds: permission.roleIds },
                    3
                );
            })
        );
        // response looks different than GET endpoint
        return new RestUserGroup(userGroup);
    }

    public async deleteUserGroupUsers({
        userGroupId,
        userEmails
    }: {
        userGroupId: string;
        userEmails: string[];
    }): Promise<void> {
        await Promise.all(
            userEmails.map((email: string) => {
                return this.request<void>("DELETE", `/api/usergroups/${userGroupId}/users/${email}`, undefined, 3);
            })
        );
    }

    public async deleteUserGroupProducts({
        userGroupId,
        instanceIds
    }: {
        userGroupId: string;
        instanceIds: string[];
    }): Promise<RestUserGroup> {
        const [userGroup] = await Promise.all(
            instanceIds.map((instanceId: string) => {
                return this.request<IRestResponseResult<RestUserGroup>>(
                    "DELETE",
                    `/api/usergroups/${userGroupId}/instancepermissions/${instanceId}`,
                    undefined,
                    3
                );
            })
        );

        return new RestUserGroup(userGroup);
    }

    public async updateUserGroup(userGroup: RestUserGroup): Promise<RestUserGroup> {
        const userGroupResponse = await this.request<IRestResponseResult<RestUserGroup>>(
            "PUT",
            `/api/usergroups/${userGroup.id}`,
            userGroup
        );

        return new RestUserGroup(userGroupResponse);
    }

    public async getUsers({
        organizationId,
        query,
        email,
        userGroupIds,
        includeExternalStatus,
        size = 20,
        page = 1
    }: {
        organizationId?: string;
        query?: string;
        email?: string;
        userGroupIds?: string[];
        includeExternalStatus?: boolean;
        size?: number;
        page?: number;
    }): Promise<Collection<RestUser>> {
        const search = new URLSearchParams();
        if (organizationId) {
            search.append("organizationId", organizationId);
        }

        if (query) {
            search.append("email", query);
            search.append("firstName", query);
            search.append("lastName", query);
        }

        if (email) {
            search.set("email", email);
        }

        if (includeExternalStatus) {
            search.append("includeExternalStatus", `${includeExternalStatus}`);
        }

        search.append("matchAnyUserGroups", "true");

        const groupIdChunks = [];
        if (userGroupIds?.length) {
            const chunkSize = 75;
            for (let i = 0; i < userGroupIds?.length; i += chunkSize) {
                groupIdChunks.push(userGroupIds?.slice(i, i + chunkSize));
            }
        }

        let users: RestUser[] = [];
        let totalItemCount = 0;
        if (groupIdChunks.length <= 1) {
            search.append("size", `${size}`);
            search.append("offset", `${(page - 1) * size}`);

            userGroupIds?.forEach((id) => {
                search.append("userGroupIds", id);
            });

            const userList = await this.request<IRestResponseResult<RestUser>>(
                "GET",
                `/api/users?${search.toString()}`
            );

            return new Collection<RestUser>({
                items: userList.items.map((user: RestUser) => new RestUser(user)),
                totalCount: userList.totalItemCount
            });
        } else {
            // THIS IS A TEMPORARY FIX UNTIL WE HAVE AN ENDPOINT FOR GETTING USERS BY INSTANCE OR ATTRIBUTE IDS.
            // In order to do paging with the batch calls, we need to get all users for each call and add them together.
            search.append("size", `${ALL_PAGES_SIZE}`);
            search.append("offset", "0");
            for (const idList of groupIdChunks) {
                search.delete("userGroupIds");
                idList.forEach((id) => {
                    search.append("userGroupIds", id);
                });
                const userChunk = await this.request<IRestResponseResult<RestUser>>(
                    "GET",
                    `/api/users?${search.toString()}`
                );

                totalItemCount += userChunk.totalItemCount;

                users.push(...userChunk.items);
            }

            //use total count and current page to get the list of users for this call.
            const start = (page - 1) * size;
            const end = start + size;
            users = users.slice(start, end);

            return new Collection<RestUser>({
                items: users.map((user: RestUser) => new RestUser(user)),
                totalCount: totalItemCount
            });
        }
    }

    public async getUsersByPermission({
        organizationId,
        productIds,
        instanceIds,
        attributeIds,
        roleIdFilters,
        query,
        includeExternalStatus,
        size = DEFAULT_PAGE_SIZE,
        page = 1
    }: {
        organizationId: string;
        productIds?: string[];
        instanceIds?: string[];
        attributeIds?: string[];
        roleIdFilters?: string[];
        query?: string;
        includeExternalStatus?: boolean;
        size?: number;
        page?: number;
    }): Promise<Collection<RestUser>> {
        const search = new URLSearchParams();

        search.append("size", `${size}`);
        search.append("offset", `${(page - 1) * size}`);

        if (query) {
            search.append("userSearchField", query);
        }

        if (productIds?.length) {
            search.append("PermissionType", "Product");

            productIds?.forEach((id) => {
                search.append("ids", id);
            });
        }

        if (instanceIds?.length) {
            search.append("PermissionType", "Instance");

            instanceIds?.forEach((id) => {
                search.append("ids", id);
            });
        }

        if (attributeIds?.length) {
            search.append("PermissionType", "Attribute");

            attributeIds?.forEach((id) => {
                search.append("ids", id);
            });
        }

        roleIdFilters?.forEach((id) => {
            search.append("roleIds", id);
        });

        if (includeExternalStatus) {
            search.append("includeExternalStatus", `${includeExternalStatus}`);
        }

        const userList = await this.request<IRestResponseResult<RestUser>>(
            "GET",
            `/api/organizations/${organizationId}/users?${search.toString()}`
        );

        return new Collection<RestUser>({
            items: userList.items.map((user: RestUser) => new RestUser(user)),
            totalCount: userList.totalItemCount
        });
    }

    public async getUserByEmail(email: string, includeExternalStatus: boolean = false): Promise<RestUser> {
        const user = await this.request<RestUser>(
            "GET",
            `/api/users/${email.toLowerCase()}/?includeExternalStatus=${includeExternalStatus}`
        );

        return new RestUser(user);
    }

    public async addUser(user: RestUser): Promise<RestUser> {
        const userResponse = await this.request<RestUser>("POST", "/api/users", user);

        return new RestUser(userResponse);
    }

    public async getUserGroupInstances({
        userGroupId
    }: {
        userGroupId: string;
    }): Promise<Collection<RestProductInstance>> {
        const userGroupInstances = await this.request<IRestResponseResult<RestProductInstance>>(
            "GET",
            `/api/usergroups/${userGroupId}/instancepermissions?size=${ALL_PAGES_SIZE}`
        );

        return new Collection<RestProductInstance>({
            items: userGroupInstances.items.map((instance: RestProductInstance) => new RestProductInstance(instance)),
            totalCount: userGroupInstances.totalItemCount
        });
    }

    public async getOrganizationProductInstances({
        organizationId
    }: {
        organizationId: string;
    }): Promise<RestOrganizationProductInstances> {
        const orgProductInstances = await this.request<RestOrganizationProductInstances>(
            "GET",
            `/api/organizations/${organizationId}/productinstances`
        );

        return new RestOrganizationProductInstances(orgProductInstances);
    }

    public async getUserGroupInstance({
        instanceId,
        userGroupId
    }: {
        instanceId: string;
        userGroupId: string;
    }): Promise<RestProductInstance> {
        const userGroupInstance = await this.request<RestProductInstance>(
            "GET",
            `/api/usergroups/${userGroupId}/instancepermissions/${instanceId}`
        );

        return new RestProductInstance(userGroupInstance);
    }

    public async getUserGroupByName({
        organizationId,
        name
    }: {
        organizationId: string;
        name: string;
    }): Promise<RestUserGroup | undefined> {
        const params = new URLSearchParams();
        organizationId && params.append("organizationId", organizationId);
        name && params.append("name", name);
        params.append("useExactMatchSearch", `${true}`);

        const userGroups = await this.request<IRestResponseResult<RestUserGroup>>(
            "GET",
            `/api/usergroups?${params.toString()}`
        );

        const listOfGroups = userGroups.items;

        if (listOfGroups && listOfGroups.length > 0) {
            const [userGroup] = listOfGroups;

            return new RestUserGroup(userGroup);
        }

        return undefined;
    }

    public async updateUser(user: RestUser): Promise<RestUser> {
        const updatedUser = await this.request<RestUser>("PUT", `/api/users/${user.email}`, user);

        return new RestUser(updatedUser);
    }

    // currently no tests for this... or msw handlers for this either
    public async updateUserFields(email: string, ...fields: any): Promise<RestUser> {
        const updatedUser = await this.request<RestUser>("PATCH", `/api/users/${email}`, ...fields);
        return new RestUser(updatedUser);
    }

    public async addOrganizationIdentityProvider({
        organizationId,
        issuer,
        ssoUrl,
        certificate
    }: {
        organizationId: string;
        issuer: string;
        ssoUrl: string;
        certificate: File;
    }): Promise<RestIdentityProvider> {
        const formData = new FormData();

        formData.append("issuer", issuer);
        formData.append("ssoUrl", ssoUrl);
        formData.append("certificate", certificate);

        // Manually fetch here so the file is not stringified in the body.
        const response = await fetch(`${this.baseURL}/api/organizations/${organizationId}/identityprovider/saml`, {
            method: "POST",
            body: formData,
            headers: {
                // Do NOT explicitly set the content-type here, per
                // https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects#sect4
                Authorization: `Bearer ${this.accessToken}`
            }
        });

        if (response.status === 500) {
            throw new Error("Failed request: (500)");
        }

        const idp = await response.json();

        if (!response.ok) {
            // Default error message if the HTTP response does not contain a more
            // specific message.
            let message = "An unexpected error occurred. Please try again.";

            if (idp instanceof Array) {
                message = idp[0].message;
            }

            throw new Error(message);
        }

        return new RestIdentityProvider(idp);
    }

    public async addSAMLIdentityProvider({
        idpName,
        organizationId,
        issuer,
        ssoUrl,
        certificate
    }: {
        idpName: string;
        organizationId: string;
        issuer: string;
        ssoUrl: string;
        certificate: File;
    }): Promise<RestIdentityProvider> {
        const formData = new FormData();

        formData.append("idpName", idpName);
        formData.append("issuer", issuer);
        formData.append("ssoUrl", ssoUrl);
        formData.append("certificate", certificate);

        // Manually fetch here so the file is not stringified in the body.
        const response = await fetch(`${this.baseURL}/api/organizations/${organizationId}/identityprovider/saml`, {
            method: "POST",
            body: formData,
            headers: {
                // Do NOT explicitly set the content-type here, per
                // https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects#sect4
                Authorization: `Bearer ${this.accessToken}`
            }
        });

        if (response.status === 500) {
            throw new Error("Failed request: (500)");
        }

        const idp = await response.json();

        if (!response.ok) {
            // Default error message if the HTTP response does not contain a more
            // specific message.
            let message = "An unexpected error occurred. Please try again.";

            if (idp instanceof Array) {
                message = idp[0].message;
            }

            throw new Error(message);
        }

        return new RestIdentityProvider(idp);
    }

    public async addOIDCIdentityProvider({
        authorizationUrl,
        clientId,
        clientSecret,
        idpName,
        issuerUrl,
        jwksUrl,
        organizationId,
        provider,
        tokenUrl,
        userInfoUrl,
        wellKnownUrl: wellknownMetadataUrl
    }: {
        authorizationUrl?: string;
        clientId: string;
        clientSecret: string;
        idpName: string;
        issuerUrl?: string;
        jwksUrl?: string;
        organizationId: string;
        provider: string;
        tokenUrl?: string;
        userInfoUrl?: string;
        wellKnownUrl?: string;
    }): Promise<RestIdentityProvider> {
        const body = {
            authorizationUrl,
            clientId,
            clientSecret,
            idpName,
            issuerUrl,
            jwksUrl,
            organizationId,
            provider,
            tokenUrl,
            userInfoUrl,
            wellknownMetadataUrl
        };

        // Manually fetch here so the file is not stringified in the body.
        const response = await fetch(`${this.baseURL}/api/organizations/${organizationId}/identityprovider/oidc`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                // Do NOT explicitly set the content-type here, per
                // https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects#sect4
                Authorization: `Bearer ${this.accessToken}`
            }
        });

        if (response.status === 500) {
            throw new Error("Failed request: (500)");
        }

        const idp = await response.json();

        if (!response.ok) {
            // Default error message if the HTTP response does not contain a more
            // specific message.
            let message = "An unexpected error occurred. Please try again.";

            if (idp instanceof Array) {
                message = idp[0].message;
            }

            throw new Error(message);
        }

        return new RestIdentityProvider(idp);
    }

    public async deleteOrganizationIdentityProvider({
        id,
        organizationId
    }: {
        id: string;
        organizationId: string;
    }): Promise<void> {
        return await this.request<void>("DELETE", `/api/organizations/${organizationId}/identityproviders/${id}`);
    }

    public async deleteOrganizationIdentityProviders({ organizationId }: { organizationId: string }): Promise<void> {
        return await this.request<void>("DELETE", `/api/organizations/${organizationId}/identityprovider`);
    }

    public async getOrganizationIdentityProviders({
        organizationId
    }: {
        organizationId: string;
    }): Promise<RestIdentityProvider[] | null> {
        const response = await this.request<{ Data: RestIdentityProvider[] }>(
            "GET",
            `/api/organizations/${organizationId}/identityproviders`
        );

        if (!response?.Data) {
            return null;
        }
        const idps = response.Data;

        return idps?.map((orgIdp) => new RestIdentityProvider(orgIdp));
    }

    public async getOrganizationDomains({ organizationId }: { organizationId: string }): Promise<string[]> {
        const orgDomains: any = await this.request<IRestResponseResult<RestIdentityProvider>>(
            "GET",
            `/api/organizations/${organizationId}/domains`
        );

        if (!orgDomains) {
            return [];
        }

        return orgDomains.Urls;
    }
    public async updateOrganization({
        organizationId,
        mfaEnabled
    }: {
        organizationId: string;
        mfaEnabled?: boolean;
    }): Promise<any> {
        const updates: any = {};
        if (mfaEnabled !== undefined) {
            updates["mfaEnabled"] = mfaEnabled;
        }

        return this.request<IRestResponseResult<any>>("PUT", `/api/organizations/${organizationId}`, updates);
    }

    public async updateOrganizationDomains({
        organizationId,
        domains
    }: {
        organizationId: string;
        domains: string[];
    }): Promise<string[]> {
        const response: any = await this.request<IRestResponseResult<RestIdentityProvider>>(
            "PUT",
            `/api/organizations/${organizationId}/domains`,
            { urls: domains }
        );

        if (!response.Urls) {
            return [];
        }

        return response.Urls;
    }

    public async updateIdentityProviderDomains({
        organizationId,
        identityProviderId,
        domains
    }: {
        organizationId: string;
        identityProviderId: string;
        domains: string[];
    }): Promise<RestIdentityProvider> {
        const response = await this.request<IRestResponseResult<RestIdentityProvider>>(
            "PUT",
            `/api/organizations/${organizationId}/identityproviders/${identityProviderId}/domains`,
            { urls: domains }
        );
        return new RestIdentityProvider(response);
    }

    public async getUserAdminCenterInstancePermissions({
        email,
        orgId
    }: {
        email: string;
        orgId?: string;
    }): Promise<RestUserInstancePermission[]> {
        const params = new URLSearchParams();
        params.append("productId", ADMINCENTER_PRODUCT_ID);

        if (orgId) {
            params.append("customerId", orgId);
        }

        const adminCenterPermissions = await this.request<IRestResponseResult<RestUserInstancePermission>>(
            "GET",
            `/api/users/${email}/instancepermissions?${params.toString()}`
        );

        const instancePermissions = adminCenterPermissions.items.map(
            (instance) => new RestUserInstancePermission(instance)
        );

        return instancePermissions;
    }

    public async getUserInstancePermissions({ email }: { email: string }): Promise<RestUserInstancePermission[]> {
        const permissions = await this.request<IRestResponseResult<RestUserInstancePermission>>(
            "GET",
            `/api/users/${email}/instancepermissions?size=${ALL_PAGES_SIZE}`
        );

        return permissions.items.map((instance) => new RestUserInstancePermission(instance));
    }

    public async getRoles({
        organizationId,
        productId,
        instanceId,
        size = DEFAULT_PAGE_SIZE,
        page = 1
    }: {
        organizationId: string;
        productId?: string;
        instanceId?: string;
        size?: number;
        page?: number;
    }): Promise<Collection<RestRole>> {
        const search = new URLSearchParams();
        // don't want to send `undefined` values for productId and instanceId
        productId && search.append("productId", productId);
        instanceId && search.append("instanceId", instanceId);

        search.append("isActive", "true");
        search.append("size", `${size}`);
        search.append("offset", `${(page - 1) * size}`);

        const roles = await this.request<IRestResponseResult<RestRole>>(
            "GET",
            `/api/organizations/${organizationId}/roles?${search.toString()}`
        );

        return new Collection<RestRole>({
            items: roles.items.map((role: RestRole) => new RestRole(role)),
            totalCount: roles.totalItemCount
        });
    }

    public async createRole(roleDetails: RoleCreationParameters): Promise<RestRole> {
        const { organizationId, instances, product, roleType, ...rest } = roleDetails;
        const isProductRole = roleType === CUSTOMER_DEFINED_PRODUCT_ROLE || instances[0].name === ALL;

        const role = await this.request<RestRole>("POST", "/api/roles", {
            ...rest,
            productId: product.id,
            roleType: isProductRole ? CUSTOMER_DEFINED_PRODUCT_ROLE : CUSTOMER_DEFINED_INSTANCE_ROLE,
            targetIds: isProductRole ? [organizationId] : instances.map((instance) => instance.id)
        });

        return new RestRole(role);
    }

    public async deleteRole({ roleId }: { roleId: string }): Promise<void> {
        return this.request<void>("DELETE", `/api/roles/${roleId}`);
    }

    public async updateRole(roleDetails: RoleCreationParameters): Promise<RestRole> {
        const { organizationId, instances, product, id: roleId, ...rest } = roleDetails;
        const isProductRole = instances[0].name === ALL;
        const role = await this.request<RestRole>("PUT", `/api/roles/${roleId}`, {
            ...rest,
            productId: product.id,
            roleType: isProductRole ? CUSTOMER_DEFINED_PRODUCT_ROLE : CUSTOMER_DEFINED_INSTANCE_ROLE,
            targetIds: isProductRole ? [organizationId] : instances.map((instance) => instance.id)
        });

        return new RestRole(role);
    }

    public async getAttributes(parameters: GetAttributeParameters): Promise<Collection<RestAttribute>> {
        const { isActive, instanceId, key, offset, productId, size, types, name } = parameters;

        const params = new URLSearchParams();

        params.append("productId", `${productId}`);
        params.append("size", `${size}`);
        params.append("offset", `${offset}`);

        if (typeof isActive === "boolean") params.append("isActive", `${isActive}`);
        if (instanceId) params.append("instanceId", instanceId);
        if (key) params.append("key", key);
        if (name) params.append("name", name);

        if (types && types.length) {
            types.forEach((type) => {
                params.append("types", type);
            });
        }

        const url = `/api/attributes?${params.toString()}`;

        const attributes = await this.request<Collection<RestAttribute>>("GET", url);

        return new Collection<RestAttribute>({
            items: attributes.items.map((attribute: RestAttribute) => new RestAttribute(attribute)),
            totalCount: attributes.totalCount
        });
    }

    public async getAttribute(id: string): Promise<RestAttribute> {
        const attribute = await this.request<RestAttribute>("GET", `/api/attributes/${id}`);
        return new RestAttribute(attribute);
    }

    public async getInvitations({
        createdBy,
        inviteeEmail,
        organizationId,
        size = 20,
        page = 1
    }: {
        createdBy?: string;
        inviteeEmail?: string;
        organizationId?: string;
        size?: number;
        page?: number;
    }): Promise<Collection<RestInvitation>> {
        const params = new URLSearchParams();
        if (organizationId) {
            params.append("organizationId", `${organizationId}`);
        }
        if (createdBy) {
            params.append("createdBy", `${createdBy}`);
        }
        if (inviteeEmail) {
            params.append("inviteeEmail", `${inviteeEmail}`);
        }
        params.append("size", `${size}`);
        params.append("offset", `${(page - 1) * size}`);

        const invitations = await this.request<IRestResponseResult<RestInvitation>>(
            "GET",
            `/api/invitations?${params.toString()}`
        );

        return new Collection<RestInvitation>({
            items: invitations.items.map((invitation: RestInvitation) => new RestInvitation(invitation)),
            totalCount: invitations.totalItemCount
        });
    }

    public async createInvitation(invitation: Partial<RestInvitation>): Promise<RestInvitation> {
        const invitationToSend = {
            ...invitation,
            userGroupIds: invitation.userGroups ? invitation.userGroups.map((ug) => ug.id) : []
        };
        const response = await this.request<IRestResponseResult<RestInvitation>>(
            "POST",
            "/api/invitations",
            invitationToSend
        );
        return new RestInvitation(response);
    }

    public async revokeInvitation(invitationId: string, requireAcceptance: boolean): Promise<void> {
        return await this.request<undefined>("POST", `/api/invitations/${invitationId}/revoke`, {
            RequireAcceptance: requireAcceptance
        });
    }

    public async resendInvitation(invitationId: string): Promise<void> {
        return await this.request<undefined>("POST", `/api/invitations/${invitationId}/resend`, {
            RequireAcceptance: true
        });
    }

    public async updateInstance({
        instance
    }: {
        instance: Partial<RestOrganizationProduct>;
    }): Promise<RestProductInstance> {
        const response = await this.request<RestProductInstance>(
            "PUT", // todo: idx to change this to patch
            `/api/instances/${instance.id}`,
            instance
        );
        return new RestProductInstance(response);
    }

    public async searchCustomers({ query }: { query: string }): Promise<Collection<RestCustomer>> {
        const params = new URLSearchParams();
        params.append("query", query);

        const response = await this.request<Collection<RestCustomer>>("GET", `/api/customers?${params.toString()}`);

        return new Collection<RestCustomer>({
            items: response.items?.map((r) => new RestCustomer(r)),
            totalCount: response.totalCount
        });
    }

    public async getProviders(): Promise<string[]> {
        return await this.request<string[]>("GET", "/api/providers");
    }

    public async getUserListByProduct({
        organizationId,
        page = 1,
        productIds,
        searchQuery = ""
    }: {
        organizationId: string;
        page?: number;
        productIds: string[];
        searchQuery?: string;
    }): Promise<Collection<RestUser>> {
        const orgProducts = await this.getOrganizationProductInstances({ organizationId });
        const products = orgProducts.products.filter((p) => productIds.includes(p.id));
        const instances = products.flatMap((p) => p.instances);
        const instanceIds = instances.map((i) => i.id);

        return this.getUserListByInstance({ instanceIds, organizationId, page, searchQuery });
    }

    public async getUserListByInstance({
        attributeIds = [],
        instanceIds,
        organizationId,
        page = 1,
        roleIds = [],
        searchQuery = ""
    }: {
        attributeIds?: string[];
        instanceIds: string[];
        organizationId?: string | undefined;
        page?: number;
        roleIds?: string[];
        searchQuery?: string;
    }): Promise<Collection<RestUser>> {
        const result = await this.getUserGroupsByInstance({
            attributeIds,
            groupTypes: [GROUP_TYPES.PRODUCT],
            instanceIds,
            organizationId,
            roleIds
        });

        const groups = result?.items;
        if (!groups?.length) {
            return Promise.resolve(new Collection<RestUser>({ items: [] }));
        }

        const groupIds = groups.map((ug) => ug.id);
        return await this.getUsers({
            userGroupIds: groupIds,
            query: searchQuery,
            size: DEFAULT_PAGE_SIZE,
            page,
            includeExternalStatus: true
        });
    }

    public async getUserListByProject({
        organizationId,
        attributeIds,
        roleIds = [],
        page = 1,
        searchQuery = ""
    }: {
        organizationId: string;
        attributeIds: string[];
        roleIds?: string[];
        page?: number;
        searchQuery?: string;
    }): Promise<Collection<RestUser>> {
        const result = await this.getUserGroupsByAttributeId({
            organizationId,
            attributeIds,
            roleIds,
            groupTypes: [GROUP_TYPES.PRODUCT]
        });
        const groups = result?.items;
        const groupIds = groups.map((ug) => ug.id);

        if (groupIds.length) {
            return await this.getUsers({
                userGroupIds: groupIds,
                query: searchQuery,
                size: DEFAULT_PAGE_SIZE,
                page
            });
        } else {
            return Promise.resolve({ items: [], totalCount: 0 });
        }
    }

    public async getUserGroupsByInstance({
        attributeIds = [],
        groupTypes = [],
        instanceIds,
        organizationId,
        roleIds = []
    }: {
        attributeIds: string[];
        groupTypes?: string[];
        instanceIds: string[];
        organizationId?: string | undefined;
        roleIds: string[];
    }): Promise<Collection<RestUserGroup>> {
        const params = new URLSearchParams();

        if (!!organizationId) params.append("organizationId", organizationId);

        instanceIds.forEach((id) => {
            params.append("instanceIds", id);
        });

        groupTypes.forEach((type) => {
            params.append("groupTypes", type);
        });

        roleIds.forEach((type) => {
            params.append("roleIds", type);
        });

        attributeIds.forEach((type) => {
            params.append("attributeIds", type);
        });

        params.append("matchAnyInstanceId", "true");
        params.append("size", `${ALL_PAGES_SIZE}`);

        const result = await this.request<Collection<RestUserGroup>>("GET", `/api/usergroups?${params.toString()}`);

        return new Collection<RestUserGroup>({
            items: result?.items.map((userGroup: RestUserGroup) => new RestUserGroup(userGroup)),
            totalCount: result.totalCount
        });
    }

    public async getUserGroupsByAttributeId({
        organizationId,
        attributeIds,
        roleIds = [],
        groupTypes = []
    }: {
        organizationId: string;
        attributeIds: string[];
        roleIds?: string[];
        groupTypes?: string[];
    }): Promise<Collection<RestUserGroup>> {
        const params = new URLSearchParams();
        params.append("organizationId", organizationId);
        attributeIds.forEach((id) => {
            params.append("attributeIds", id);
        });
        groupTypes.forEach((type) => {
            params.append("groupTypes", type);
        });
        roleIds?.forEach((id) => {
            params.append("roleIds", id);
        });

        const result = await this.request<Collection<RestUserGroup>>(
            "GET",
            `/api/usergroups?${params.toString()}&size=${ALL_PAGES_SIZE}`
        );

        return new Collection<RestUserGroup>({
            items: result?.items.map((userGroup: RestUserGroup) => new RestUserGroup(userGroup)),
            totalCount: result.totalCount
        });
    }

    public async migrateUser({ email, organizationId }: { email: string; organizationId: string }): Promise<RestUser> {
        const updatedUser = await this.request<RestUser>("POST", `/api/users/${email}/migrate/${organizationId}`);
        return new RestUser(updatedUser);
    }

    public async getOrganization({ id }: { id: string }): Promise<RestOrganization> {
        const org = await this.request<RestOrganization>("GET", `/api/organizations/${id}`);
        return new RestOrganization(org);
    }

    public async getBillingNotifications({
        organizationId
    }: {
        organizationId: string;
    }): Promise<{ Settings: { billingNotifications: BillingNotification[] } }> {
        return await this.request<{ Settings: { billingNotifications: BillingNotification[] } }>(
            "GET",
            `/api/organizations/${organizationId}/billingnotifications`
        );
    }

    public async updateBillingNotification({
        notificationId,
        organizationId,
        updates
    }: {
        notificationId: string;
        organizationId: string;
        updates: Pick<BillingNotification, "enabled" | "recipients">;
    }): Promise<BillingNotification> {
        return this.request("PUT", `/api/organizations/${organizationId}/billingnotifications/${notificationId}`, {
            ...updates
        });
    }

    public async getLocalLoginSettings({
        organizationId
    }: {
        organizationId: string;
    }): Promise<{ Settings: LocalLoginSettings }> {
        return await this.request<{ Settings: LocalLoginSettings }>(
            "GET",
            `/api/organizations/${organizationId}/settings/locallogin`
        );
    }

    public async getOrganizationSettings({
        organizationId
    }: {
        organizationId: string;
    }): Promise<{ Settings: RestOrganizationSettings }> {
        return await this.request<{ Settings: RestOrganizationSettings }>(
            "GET",
            `/api/organizations/${organizationId}/settings`
        );
    }

    public async updateGenAISettings({
        organizationId,
        settings
    }: {
        organizationId: string;
        settings: { enabled: boolean };
    }): Promise<{ enabled: boolean }> {
        return await this.request<{ enabled: boolean }>(
            "PUT",
            `/api/organizations/${organizationId}/settings/genai`,
            settings
        );
    }

    public async updateLocalLoginSettings({
        organizationId,
        settings
    }: {
        organizationId: string;
        settings: { enabled: boolean; mfaEnabled: boolean };
    }): Promise<LocalLoginSettings> {
        return await this.request<LocalLoginSettings>(
            "PUT",
            `/api/organizations/${organizationId}/settings/locallogin`,
            { ...settings }
        );
    }

    public async updateOrganizationSettings({
        organizationId,
        updates
    }: {
        organizationId: string;
        updates: Partial<RestOrganizationSettings>;
    }): Promise<{ Settings: RestOrganizationSettings }> {
        return await this.request<{ Settings: RestOrganizationSettings }>(
            "PATCH",
            `/api/organizations/${organizationId}/settings`,
            updates
        );
    }
}
