import { ButtonIcon, Icon, Poptip, Table } from "@optimizely/axiom";
import React, { useCallback } from "react";
import { SupportOrganizationPermissions } from "../../pages/Support/Access/SupportAccess";
import { IUserInstancePermission } from "../../../domain/UserInstancePermission";
import { Role } from "../../../domain/Role";

type DetailedSupportTableRowProps = {
    orgPermission: SupportOrganizationPermissions;
    onRemove: ({ orgPermission }: { orgPermission: SupportOrganizationPermissions }) => void;
};

export const DetailedSupportTableRow = (props: DetailedSupportTableRowProps) => {
    const { orgPermission, onRemove } = props;
    const { revokeDate, organizationName, organizationId, instancePermissions } = orgPermission;

    const getNavigationUrl = useCallback((instancePermission: IUserInstancePermission, role?: Role): string => {
        let url = instancePermission.navigationUrl;
        if (instancePermission.productId === process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID && role) {
            const projectAttribute = role.attributes.find((a) => a.key !== "permissions");
            if (projectAttribute) {
                url = `${url}/projects/${projectAttribute.key}`;
            }
        }
        return url;
    }, []);

    const AccessLevelLink = ({
        instancePermission,
        role,
        hideBottomBorder = true
    }: {
        instancePermission: IUserInstancePermission;
        role?: Role;
        hideBottomBorder: boolean;
    }) => {
        return (
            <div className={`push--bottom ${hideBottomBorder ? "no-" : "soft--bottom "}border--bottom`}>
                <a
                    href={getNavigationUrl(instancePermission, role)}
                    target="_blank"
                    rel="noreferrer"
                    className="flex flex--row"
                >
                    <div className="push--right">
                        <Icon
                            name="arrow-up-right-from-square"
                            size="small"
                            className="weight--bold vertical-align--middle"
                            color="blue"
                        />
                    </div>
                    <div>{`${instancePermission.productName} | ${instancePermission.instanceName} ${
                        role ? `| ${role.name}` : ""
                    }`}</div>
                </a>
            </div>
        );
    };

    return (
        <Table.TR key={organizationId}>
            <Table.TD>{organizationName}</Table.TD>
            <Table.TD>
                {instancePermissions.map((ip, ipIndex) => {
                    const { roles } = ip;
                    return roles.length > 0 ? (
                        roles.map((r, rIndex) => {
                            return (
                                <AccessLevelLink
                                    key={rIndex}
                                    instancePermission={ip}
                                    role={r}
                                    hideBottomBorder={
                                        ipIndex === instancePermissions.length - 1 && rIndex === roles.length - 1
                                    }
                                />
                            );
                        })
                    ) : (
                        <AccessLevelLink
                            key={`top-level-access-link-${ip.instanceId}`}
                            instancePermission={ip}
                            hideBottomBorder={ipIndex === instancePermissions.length - 1}
                        />
                    );
                })}
            </Table.TD>
            <Table.TD>{revokeDate && new Date(revokeDate).toLocaleString()}</Table.TD>
            <Table.TD style={{ textAlign: "right" }}>
                <Poptip isInline trigger="mouseenter" content={"Remove organization access..."} className="">
                    <ButtonIcon
                        size="small"
                        /* eslint-disable-next-line react/style-prop-object */
                        style="plain"
                        title={"Remove Access"}
                        iconName={"trash-can"}
                        onClick={() => onRemove({ orgPermission })}
                    />
                </Poptip>
            </Table.TD>
        </Table.TR>
    );
};

DetailedSupportTableRow.displayName = "DetailedSupportTableRow";
