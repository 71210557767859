/* eslint-disable react/style-prop-object */
import React, { useCallback, useEffect, useState } from "react";
import { Badge, Dropdown, Button, PaginationControls, Table, DialogNew } from "@optimizely/axiom";

import { useUserGroups } from "../../../hooks/useUserGroups/useUserGroups";
import { UserGroup } from "../../../domain/UserGroup";
import "./UserGroups.css";
import { UserGroupForm } from "../../components/UserGroupForm/UserGroupForm/UserGroupForm";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { Flags } from "../../../feature-flags/flags";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { useUserGroupContext } from "../../components/UserGroupForm/UserGroupFormContext/UserGroupContext";
import { MoreMenu } from "../../components/MoreMenu/MoreMenu";
import {
    ADMINCENTER_GROUP_NAME,
    ATTRIBUTE_ROLES,
    DEFAULT_DEBOUNCE,
    EVERYONE_GROUP_NAME,
    GROUP_TYPES,
    REFETCH_PERMISSIONS_EVENT_NAME,
    ALL_PAGES_SIZE
} from "../../../constants/constants";
import { useDebounce } from "../../../hooks/useDebounce/useDebounce";
import { SearchInput } from "../../components/SearchInput/SearchInput";
import { useUserContext } from "../../../providers/UserProvider";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { useGroupsByUser } from "../../../hooks/useGroupsByUser/useGroupsByUser";
import { ResetFilterPrompt } from "../../components/ResetFilterPrompt/ResetFilterPrompt";
import { datadogRum } from "@datadog/browser-rum";
import { listIsDirty } from "../../../lib/utils";
import LimitByRole from "../../components/LimitByRole/LimitByRole";
import { useOrgUserGroups } from "../../../hooks/useOrgUserGroups/useOrgUserGroups";
import { InstancePermission } from "../../../domain/InstancePermission";
import { UserGroupFilterDropdown } from "../../components/UserGroupFilterDropdown/UserGroupFilterDropdown";
import { useProducts } from "../../../hooks/useProducts/useProducts";
import { useRoles } from "../../../hooks/useRoles/useRoles";
import { getEntitledProductRoles } from "../../../lib/access-helpers";
import { emitToast } from "../../../lib/toaster-utils";

const UserGroups = () => {
    const [showGroupForm, setShowGroupForm] = useState(false);
    const [groupSelectedForDeletion, setGroupSelectedForDeletion] = useState<UserGroup | null>(null);
    const [loading, setLoading] = useState(false);
    const [usersToUpdate, setUsersToUpdate] = useState<string[]>([]);

    const { enabled: hideProductGroups } = useFeatureFlag(Flags.HIDE_PRODUCT_GROUPS);
    const { organizationId, profile } = useUserContext();
    const { userGroupState, updateUserGroupState } = useUserGroupContext();
    const { userGroup: selectedGroup } = userGroupState;

    const ALL_GROUP_TYPES = "All";
    const [groupType, setGroupType] = useState(ALL_GROUP_TYPES);

    const {
        debouncedValue: debouncedSearchQuery,
        value: searchQuery,
        setValue: setSearchQuery
    } = useDebounce<string>("", DEFAULT_DEBOUNCE);
    const {
        userGroups,
        isLoading,
        isSearching,
        // TODO: fix error handling for SWR methods other than GET; currently
        // only the initial GET of a user group is going to populate this `error` object
        error,
        currentPage,
        setCurrentPage,
        addUserGroup,
        deleteUserGroup,
        updateUserGroup,
        pageSize,
        totalCount
    } = useUserGroups({
        organizationId,
        query: debouncedSearchQuery.length > 2 ? debouncedSearchQuery : "",
        groupType: hideProductGroups ? undefined : groupType !== ALL_GROUP_TYPES ? groupType : "",
        groupTypes: !hideProductGroups ? [] : groupType !== ALL_GROUP_TYPES ? [groupType] : ["Internal", "Custom"]
    });

    const { enabled: filterGroupsPerEntitlements } = useFeatureFlag(Flags.FILTER_GROUPS_AND_ROLES_PER_ENTITLEMENTS);

    const { roles } = useRoles({
        organizationId: organizationId,
        size: ALL_PAGES_SIZE
    });
    const { products = [], instances = [] } = useProducts({ organizationId });
    let filteredUserGroups = userGroups;
    if (filterGroupsPerEntitlements) {
        // Step 1: Get roles corresponding to products thaat the Org is entitled for
        const filteredRoles = getEntitledProductRoles({ products, instances, roles: roles || [] });

        // Step 2: Filter usergroups which are internal, have no instance permissions,
        // have no role restrictions on instance permissions or which have access via filtered roles.
        const isInternal = ({ userGroup }: { userGroup: UserGroup }) => userGroup.groupType === GROUP_TYPES.INTERNAL;
        const hasNoInstancePermissions = ({ userGroup }: { userGroup: UserGroup }) =>
            userGroup.instancePermissions.length === 0;
        const hasAccessViaRole = ({ userGroup }: { userGroup: UserGroup }) => {
            return userGroup.instancePermissions.filter((permission) => {
                const adminRole = permission.roleIds.length === 0;
                const hasRoleAccess = permission.roleIds.some(
                    (roleId) => filteredRoles?.some((role) => role.id === roleId)
                );
                return adminRole || hasRoleAccess;
            }).length;
        };
        filteredUserGroups = userGroups?.filter(
            (userGroup) =>
                isInternal({ userGroup }) || hasNoInstancePermissions({ userGroup }) || hasAccessViaRole({ userGroup })
        );
    }

    const { revalidate: revalidateOrgUserGroups } = useOrgUserGroups({ organizationId });

    const { revalidate } = useGroupsByUser({
        organizationId: organizationId || "",
        email: usersToUpdate[0]
    });

    const totalPages = Math.ceil(totalCount / pageSize);
    const showPagination = totalPages >= 2;

    const { enabled: showUserGroupOwnerColumn } = useFeatureFlag(Flags.SHOW_USER_GROUP_OWNER_COLUMN_FLAG);
    const { enabled: showUserGroupUserCountColumn } = useFeatureFlag(Flags.SHOW_USER_GROUP_USER_COUNT_COLUMN_FLAG);

    const memoizedRevalidate = useCallback(revalidate, [revalidate]);

    useEffect(() => {
        setShowGroupForm(!!selectedGroup);
    }, [selectedGroup]);

    useEffect(() => {
        return () =>
            updateUserGroupState({
                userGroup: null,
                productInstances: [],
                users: [],
                description: ""
            });
    }, [updateUserGroupState]);

    useEffect(() => {
        setCurrentPage(1);
    }, [debouncedSearchQuery, setCurrentPage]);

    useEffect(() => {
        const [firstUserToUpdate, ...remainingUsersToUpdate] = usersToUpdate;

        if (!firstUserToUpdate) return;

        memoizedRevalidate();
        setUsersToUpdate(remainingUsersToUpdate);
    }, [usersToUpdate, memoizedRevalidate]);

    const showForm = () => {
        setShowGroupForm(true);
    };

    const openViewUserGroup = ({
        userGroup: userGroupToView,
        editMode = false
    }: {
        editMode?: boolean;
        userGroup: UserGroup;
    }) => {
        updateUserGroupState({
            userGroup: userGroupToView,
            editing: editMode,
            description: userGroupToView.description,
            initialUsers: []
        });
    };

    const handleFormCancel = () => {
        if (selectedGroup) {
            updateUserGroupState({
                userGroup: null,
                productInstances: [],
                users: [],
                initialUsers: []
            });
        } else {
            updateUserGroupState({ description: "", initialUsers: [], users: [] });
            setShowGroupForm(false);
        }
    };

    const goToPage = (page = 1) => {
        handleFormCancel();
        setCurrentPage(page);
    };

    const updateStaleUsersData = ({
        previousUsers,
        updatedUsers
    }: {
        previousUsers: string[];
        updatedUsers: string[];
    }) => {
        const usersToUpdate = Array.from(new Set([...previousUsers, ...updatedUsers]));
        setUsersToUpdate(usersToUpdate);
    };

    const updateAppropriateUsers = ({
        previousGroupName,
        previousGroupPermissions,
        previousUsers,
        updatedGroupName,
        updatedGroupPermissions,
        updatedUsers
    }: {
        previousGroupName: string;
        previousGroupPermissions: InstancePermission[];
        previousUsers: string[];
        updatedGroupName: string;
        updatedGroupPermissions: InstancePermission[];
        updatedUsers: string[];
    }) => {
        if (previousGroupName !== updatedGroupName) return updateStaleUsersData({ previousUsers, updatedUsers });

        const groupPermissionsHadChanged =
            new Set(previousGroupPermissions).size !== new Set(updatedGroupPermissions).size;
        if (groupPermissionsHadChanged) return updateStaleUsersData({ previousUsers, updatedUsers });

        const usersToUpdate = updatedUsers
            .filter((user) => !previousUsers.includes(user))
            .concat(previousUsers.filter((user) => !updatedUsers.includes(user)));

        setUsersToUpdate(usersToUpdate);
    };

    // TODO: This is seperated from cancel in case we need to do additional distinctions like show a toast / success
    // message. If not needed should be combined with `handleFormCancel` and renamed.
    const handleFormCompletion = ({
        previousUserGroup,
        updatedUserGroup
    }: {
        previousUserGroup: UserGroup | null;
        updatedUserGroup: UserGroup | null;
    }): Promise<any> => {
        if (!updatedUserGroup) {
            return updateUserGroupState({
                userGroup: null,
                productInstances: [],
                users: [],
                description: ""
            });
        }

        if (previousUserGroup) {
            const sendEvent =
                profile?.email &&
                (previousUserGroup.users.includes(profile?.email) !== updatedUserGroup.users.includes(profile?.email) ||
                    listIsDirty(
                        previousUserGroup.instancePermissions.map((i) => i.instanceId),
                        updatedUserGroup.instancePermissions.map((i) => i.instanceId)
                    ));

            const staleDataToUpdate = {
                previousGroupName: previousUserGroup.name,
                previousGroupPermissions: previousUserGroup.instancePermissions,
                previousUsers: previousUserGroup.users,
                updatedGroupName: updatedUserGroup.name,
                updatedGroupPermissions: updatedUserGroup.instancePermissions,
                updatedUsers: updatedUserGroup.users
            };

            return updateUserGroup({ previousUserGroup, updatedUserGroup })
                .then(() => {
                    revalidateOrgUserGroups();
                    updateAppropriateUsers(staleDataToUpdate);
                    if (sendEvent) {
                        window.dispatchEvent(new CustomEvent(REFETCH_PERMISSIONS_EVENT_NAME));
                    }

                    updateUserGroupState({
                        userGroup: null,
                        productInstances: [],
                        users: [],
                        description: ""
                    });
                })
                .catch((e) => {
                    console.error(e);
                    datadogRum.addError(e);
                    throw e;
                });
        }

        return addUserGroup(updatedUserGroup).then((res) => {
            revalidateOrgUserGroups();
            updateStaleUsersData({ previousUsers: [], updatedUsers: updatedUserGroup.users });
            updateUserGroupState({ description: "" });
            setShowGroupForm(false);
            return res;
        });
    };

    const deleteGroup = () => {
        setLoading(true);
        deleteUserGroup({ userGroupId: groupSelectedForDeletion!.id })
            .then(() => {
                const removedIndex = filteredUserGroups!.findIndex(
                    (group) => group.id === groupSelectedForDeletion!.id
                );
                const previousUsers =
                    filteredUserGroups!.find((group) => group.id === groupSelectedForDeletion!.id)?.users || [];
                filteredUserGroups!.splice(removedIndex, 1);
                emitToast({ message: "Group successfully deleted." });
                setLoading(false);
                revalidateOrgUserGroups();
                updateStaleUsersData({ previousUsers, updatedUsers: [] });
                setGroupSelectedForDeletion(null);
            })
            .catch((e) => {
                console.error(e);
                datadogRum.addError(e);
                setLoading(false);
            });
    };

    if (isLoading && !isSearching) return <LoadingIndicator height="100%" type="spinner" />;

    if (error) {
        console.error(error);
        datadogRum.addError(error);
        return <div>Error fetching user group.</div>;
    }

    return (
        // <LimitByRole mode="unauthorized" action={ATTRIBUTE_ROLES.GROUPS.READ}>
        <div className="usergroup-list-page list-page">
            <div className="usergroup-list-bar">
                <div className="left-grouping">
                    <div className="usergroup-search">
                        <SearchInput
                            placeholder="Search groups by name..."
                            type="text"
                            value={searchQuery}
                            onChange={({ value }) => {
                                setSearchQuery(value);
                            }}
                        />
                    </div>
                    <div className="group-type-filter">
                        <UserGroupFilterDropdown
                            selectedGroupType={groupType}
                            selectGroupType={setGroupType}
                            hideProductGroup={hideProductGroups}
                        />
                    </div>
                </div>
                <LimitByRole action={ATTRIBUTE_ROLES.GROUPS.CREATE} mode="hide">
                    <div className="add-usergroup">
                        <Button style="highlight" onClick={showForm}>
                            Add Group...
                        </Button>
                    </div>
                </LimitByRole>
            </div>

            <Sidebar heading={selectedGroup ? "Group" : "Add Group"} isOpen={showGroupForm} onCancel={handleFormCancel}>
                <UserGroupForm onCancel={handleFormCancel} onSubmit={handleFormCompletion} />
            </Sidebar>

            <div className="usergroup-list-table list-table flex flex--column">
                {isSearching && <LoadingIndicator height="100%" type="spinner" overlay />}
                {((debouncedSearchQuery.length > 2 && !filteredUserGroups?.length) ||
                    (groupType !== ALL_GROUP_TYPES && !filteredUserGroups?.length)) && (
                    <ResetFilterPrompt
                        onClick={() => {
                            setSearchQuery("");
                            setGroupType(ALL_GROUP_TYPES);
                        }}
                        prompt="No user groups match your filter."
                    />
                )}
                {filteredUserGroups && filteredUserGroups.length > 0 && (
                    <>
                        <div className="user-manager__table">
                            <Table density="loose" style="rule-no-bottom-border">
                                <Table.THead>
                                    <Table.TR>
                                        <Table.TH textAlign="left" width="50%">
                                            Name
                                        </Table.TH>
                                        {showUserGroupOwnerColumn && <Table.TH width="20%">Owner</Table.TH>}
                                        {showUserGroupUserCountColumn && <Table.TH width="8%">Users</Table.TH>}
                                        <Table.TH width="10%">Group Type</Table.TH>
                                        <Table.TH width="18%">Created</Table.TH>
                                        <Table.TH width="18%">Modified</Table.TH>
                                        <Table.TH width="60px" />
                                    </Table.TR>
                                </Table.THead>
                                <Table.TBody>
                                    {filteredUserGroups?.map((userGroup) => {
                                        const hideAddUserOption = userGroup.groupType === GROUP_TYPES.INTERNAL;
                                        const hideDeleteOption =
                                            [EVERYONE_GROUP_NAME, ADMINCENTER_GROUP_NAME].includes(userGroup?.name) ||
                                            (userGroup.groupType &&
                                                [GROUP_TYPES.INTERNAL, GROUP_TYPES.PRODUCT].includes(
                                                    userGroup.groupType as GROUP_TYPES
                                                ));
                                        const hideMoreMenu = hideAddUserOption && hideDeleteOption;

                                        return (
                                            <Table.TR
                                                key={userGroup.id}
                                                isHighlighted={selectedGroup?.id === userGroup.id && showGroupForm}
                                            >
                                                <Table.TD>
                                                    <button
                                                        className="link button-as-link"
                                                        type="button"
                                                        onClick={() => openViewUserGroup({ userGroup })}
                                                    >
                                                        {userGroup.name}
                                                    </button>
                                                    {userGroup.description && (
                                                        <div className="muted micro">{userGroup.description}</div>
                                                    )}
                                                </Table.TD>
                                                {showUserGroupOwnerColumn && (
                                                    <Table.TD>{userGroup.groupOwner}</Table.TD>
                                                )}
                                                {showUserGroupUserCountColumn && (
                                                    <Table.TD>
                                                        <Badge>{userGroup.userCount}</Badge>
                                                    </Table.TD>
                                                )}
                                                <Table.TD>
                                                    <Badge
                                                        className="user-group-type-badge"
                                                        color={
                                                            userGroup.groupType === GROUP_TYPES.PRODUCT
                                                                ? "default"
                                                                : userGroup.groupType === GROUP_TYPES.INTERNAL
                                                                ? "purple"
                                                                : "new"
                                                        }
                                                    >
                                                        {userGroup.groupType || "Custom"}
                                                    </Badge>
                                                </Table.TD>
                                                <Table.TD>
                                                    {userGroup.created.toLocaleString("en-US", {
                                                        year: "numeric",
                                                        day: "numeric",
                                                        month: "short",
                                                        hour12: true,
                                                        hour: "numeric",
                                                        minute: "numeric"
                                                    })}
                                                </Table.TD>
                                                <Table.TD>
                                                    {userGroup.modified?.toLocaleString("en-US", {
                                                        year: "numeric",
                                                        day: "numeric",
                                                        month: "short",
                                                        hour12: true,
                                                        hour: "numeric",
                                                        minute: "numeric"
                                                    })}
                                                </Table.TD>
                                                <LimitByRole action={ATTRIBUTE_ROLES.GROUPS.UPDATE} mode="hide">
                                                    <Table.TD colSpan={1} className="user-manager__table--more-menu">
                                                        {!hideMoreMenu && (
                                                            <MoreMenu>
                                                                {!hideAddUserOption && (
                                                                    <Dropdown.ListItem>
                                                                        <Dropdown.BlockLink
                                                                            onClick={() =>
                                                                                openViewUserGroup({
                                                                                    userGroup,
                                                                                    editMode: true
                                                                                })
                                                                            }
                                                                        >
                                                                            Add user to group...
                                                                        </Dropdown.BlockLink>
                                                                    </Dropdown.ListItem>
                                                                )}
                                                                {!hideDeleteOption && (
                                                                    <Dropdown.ListItem>
                                                                        <Dropdown.BlockLink
                                                                            onClick={() =>
                                                                                setGroupSelectedForDeletion(userGroup)
                                                                            }
                                                                        >
                                                                            <span className="danger">
                                                                                Delete group...
                                                                            </span>
                                                                        </Dropdown.BlockLink>
                                                                    </Dropdown.ListItem>
                                                                )}
                                                            </MoreMenu>
                                                        )}
                                                    </Table.TD>
                                                </LimitByRole>
                                            </Table.TR>
                                        );
                                    })}
                                </Table.TBody>
                            </Table>
                        </div>
                        {showPagination && (
                            <PaginationControls
                                className="anchor--bottom"
                                currentPage={currentPage}
                                goToPage={(page: number) => goToPage(page)}
                                totalPages={totalPages}
                            />
                        )}
                    </>
                )}
            </div>

            {!!groupSelectedForDeletion && (
                <DialogNew
                    children=""
                    footerButtonList={[
                        <Button
                            key={0}
                            isDisabled={loading}
                            style="plain"
                            onClick={() => setGroupSelectedForDeletion(null)}
                        >
                            Cancel
                        </Button>,
                        <Button key={1} isDisabled={loading} style="danger" onClick={deleteGroup}>
                            Delete Group
                        </Button>
                    ]}
                    subtitle="Deleting this group will cause any users in it to lose access to assigned products."
                    title="Delete Group"
                    hasCloseButton
                    hasOverlay
                    onClose={() => setGroupSelectedForDeletion(null)}
                />
            )}
        </div>
        // </LimitByRole>
    );
};

export default UserGroups;
