/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { SWRConfig } from "swr";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { useNavigate, useLocation } from "react-router-dom";
import { LoginCallback, Security } from "@okta/okta-react";

import { UserService } from "./services/UserService";
import { config } from "./config";
import { IdentityRepository } from "./repositories/IdentityRepository";
import { UserProvider } from "./providers/UserProvider";
import { ServicesProvider } from "./providers/ServicesProvider";
import UserGroups from "./ui/pages/UserGroups/UserGroups";
import { Invitations } from "./ui/pages/Invitations/Invitations";
import { Roles } from "./ui/pages/Roles/Roles";
import { Products } from "./ui/pages/Products/Products";
import { AccessManagementProducts } from "./ui/pages/Access/AccessManagementProducts";
import { NotFound } from "./ui/pages/NotFound/NotFound";
import { RequireAuth } from "./ui/components/Auth/RequireAuth";
import { Logout } from "./ui/components/Auth/Logout";
import { LoadingIndicator } from "./ui/components/LoadingIndicator/LoadingIndicator";
import { SSOSetup } from "./ui/pages/SSOSetup/SSOSetup";
import { Feedback } from "@optimizely/axiom";
import { FeedbackTypes } from "@optimizely/axiom/dist/components/Feedback/feedback";
import { ACCESS_TYPES, ATTRIBUTE_ROLES, ROUTE_VIEW_MAPPINGS, TOASTER_EVENT_NAME } from "./constants/constants";
import { useSession } from "./hooks/useSession/useSession";
import { Usage } from "./ui/pages/Usage/Usage";
import { datadogRum } from "@datadog/browser-rum";
import { AttributeService } from "./services/AttributeService";
import "./globals.scss";
import { ProductInstances } from "./ui/pages/ProductInstances/ProductInstances";
import { AccessManagementProductInstances } from "./ui/pages/Access/AccessManagementProductInstances";
import { AccessManagementProductInstanceProjects } from "./ui/pages/Access/AccessManagementProductInstanceProjects";
import { AccessManagementProjectUsers } from "./ui/pages/Access/AccessManagementProjectUsers";
import { AccessManagementUsers } from "./ui/components/Access/AccessManagementUsers";
import { InstanceService } from "./services/InstanceService";
import { Access } from "./ui/pages/Support/Access/Access";
import { SupportAccess } from "./ui/pages/Support/Access/SupportAccess";
import { AccountActivation } from "./ui/pages/Support/AccountActivation/AccountActivation";
import { InvitationService } from "./services/InvitationService";
import { CustomerService } from "./services/CustomerService";
import { MFA } from "./ui/pages/MFA/MFA";
import { NavigateToAuthorized } from "./ui/components/Auth/NavigateToAuthorized";
import { IdpService } from "./services/Idp/IdpService";
import { DomainsPage } from "./ui/pages/Domains/Domains";
import { AccessManagementInstanceDetails } from "./ui/components/Access/AccessManagementInstanceDetails";
import { AccessManagementUserFormProvider } from "./ui/components/Access/AccessManagementUserForm/AccessManagementUserFormProvider";
import { MasterDashboard } from "./ui/pages/Support/MasterDashboard/MasterDashboard";
import { UserMigrationPage } from "./ui/pages/UserMigration/UserMigration";
import { UserManagementRouting } from "./ui/components/RoutingRules/UserManagementRouting/UserManagementRouting";
import { Flags } from "./feature-flags/flags";
import { OrganizationService } from "./services/Organization/OrganizationService";
import { UpdateSynchronizer } from "./ui/components/UpdateSynchronizer/UpdateSynchronizer";
import { AnalyticsTracking } from "./ui/components/AnalyticsTracking/AnalyticsTracking";
import { SettingsRouting } from "./ui/components/RoutingRules/SettingsRouting/SettingsRouting";
import { BillingNotificationsPage } from "./ui/pages/Settings/BillingNotifications/BillingNotificationsPage";
import { NotificationService } from "./services/Notifications/NotificationsService";
import { UserFlow } from "./ui/components/UserFlow/UserFlow";
import { OrganizationSettingsService } from "./services/OrganizationSettings/OrganizationSettingsService";
import { LocalLoginSettingsPage } from "./ui/pages/Settings/LocalLoginSettings/LocalLoginSettingsPage";
import { AuthorizedPageAccess } from "./ui/components/AuthorizedPageAccess/AuthorizedPageAccess";
import { FeatureFlagSwitch } from "./ui/components/FeatureFlagSwitch/FeatureFlagSwitch";
import { GenAIPage } from "./ui/pages/Settings/GenAI/GenAIPage";

datadogRum.init({
    applicationId: config.DATADOG_APP_ID!,
    clientToken: config.DATADOG_CLIENT_TOKEN!,
    site: "us3.datadoghq.com",
    service: "admin-center",
    env: config.APP_ENVIRONMENT,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackViewsManually: true,
    trackFrustrations: true,
    defaultPrivacyLevel: "mask-user-input"
});

datadogRum.startSessionReplayRecording();

const BASE_URL = config.SERVICE_URL;
const oktaAuth = new OktaAuth({
    issuer: config.ISSUER,
    clientId: config.CLIENT_ID,
    redirectUri: config.REDIRECT_URI,
    scopes: config.SCOPE,
    pkce: config.PKCE,
    responseType: config.RESPONSE_TYPE
});

// Create a route and context wrapper here so that services are only instantiated
// inside the <Security /> context provided in the exported component.
const AppRoutesWithContext = () => {
    const { accessToken } = useSession();
    const location = useLocation();
    const [toast, setToast] = useState<{ type: FeedbackTypes | undefined; message: string } | null>(null);
    const navigate = useNavigate();

    const identityRepository = new IdentityRepository(BASE_URL, accessToken as string);
    const customerService = new CustomerService(identityRepository);
    const userService = new UserService(identityRepository);
    const attributeService = new AttributeService(identityRepository);
    const idpService = new IdpService(identityRepository);
    const instanceService = new InstanceService(identityRepository);
    const invitationService = new InvitationService(identityRepository);
    const notificationsService = new NotificationService(identityRepository);
    const organizationService = new OrganizationService(identityRepository);
    const organizationSettingsService = new OrganizationSettingsService(identityRepository);

    const handleToast = useCallback((eventDetails: any) => {
        const { detail } = eventDetails || {};
        setToast(detail);
    }, []);

    const handleLogout = useCallback(async () => {
        navigate("/logout");
    }, [navigate]);

    useEffect(() => {
        document.addEventListener(TOASTER_EVENT_NAME, handleToast as EventListener);
        return () =>
            document.removeEventListener(TOASTER_EVENT_NAME, handleToast as EventListenerOrEventListenerObject);
    }, [handleToast]);

    useEffect(() => {
        window.addEventListener("@opti-common-header:logout", handleLogout as EventListenerOrEventListenerObject);

        return () => {
            window.removeEventListener(
                "@opti-common-header:logout",
                handleLogout as EventListenerOrEventListenerObject
            );
        };
    }, [handleLogout]);

    useEffect(() => {
        let viewName;
        const productBasedFlowRoute = /\/access\/products/;

        if (productBasedFlowRoute.test(location.pathname)) {
            viewName = location.pathname;
        } else {
            viewName = ROUTE_VIEW_MAPPINGS[location.pathname]?.name || location.pathname.split("/").pop();
        }

        datadogRum.startView({
            name: viewName
        });
    }, [location]);

    return (
        <>
            <ServicesProvider
                services={{
                    attributeService,
                    customerService,
                    idpService,
                    instanceService,
                    invitationService,
                    notificationsService,
                    organizationService,
                    userService,
                    organizationSettingsService
                }}
            >
                <UserProvider>
                    <UserFlow />
                    <UpdateSynchronizer />
                    <AnalyticsTracking />
                    <Routes>
                        <Route
                            element={<LoginCallback loadingElement={<LoadingIndicator logo />} />}
                            path="/callback"
                        />
                        <Route element={<Logout />} path="/logout" />
                        <Route element={<RequireAuth />} path="/">
                            <Route element={<NavigateToAuthorized />} path="/" />

                            <Route path="entitlements">
                                <Route element={<Products />} path="" />
                                <Route element={<ProductInstances />} path="/entitlements/:productId" />
                            </Route>

                            <Route
                                path="access"
                                element={
                                    <AuthorizedPageAccess
                                        action={ATTRIBUTE_ROLES.PRODUCTS.READ}
                                        featureFlag={Flags.ENABLE_ACCESS_MANAGEMENT}
                                    />
                                }
                            >
                                <Route element={<Navigate to="/access/products" replace />} path="" />
                                <Route element={<AccessManagementProducts />} path="products" />
                                <Route element={<AuthorizedPageAccess action={ATTRIBUTE_ROLES.USERS.READ} />}>
                                    <Route
                                        element={
                                            <AccessManagementUserFormProvider>
                                                <AccessManagementUsers />
                                            </AccessManagementUserFormProvider>
                                        }
                                        path="users"
                                    />
                                </Route>
                                <Route path="products/:productId">
                                    <Route element={<AccessManagementProductInstances />} path="instances" />
                                    <Route element={<AuthorizedPageAccess action={ATTRIBUTE_ROLES.USERS.READ} />}>
                                        <Route
                                            element={
                                                <AccessManagementUserFormProvider>
                                                    <AccessManagementUsers />
                                                </AccessManagementUserFormProvider>
                                            }
                                            path="users"
                                        />
                                    </Route>
                                    <Route path="instances/:instanceId">
                                        <Route element={<AccessManagementProductInstanceProjects />} path="projects" />
                                        <Route element={<AuthorizedPageAccess action={ATTRIBUTE_ROLES.USERS.READ} />}>
                                            <Route
                                                element={
                                                    <AccessManagementUserFormProvider>
                                                        <AccessManagementUsers />
                                                    </AccessManagementUserFormProvider>
                                                }
                                                path="users"
                                            />
                                        </Route>
                                        <Route element={<AccessManagementInstanceDetails />} path="details" />
                                        <Route
                                            path="projects/:projectId"
                                            element={<AuthorizedPageAccess action={ATTRIBUTE_ROLES.USERS.READ} />}
                                        >
                                            <Route
                                                element={
                                                    <AccessManagementUserFormProvider>
                                                        <AccessManagementProjectUsers />
                                                    </AccessManagementUserFormProvider>
                                                }
                                                path="users"
                                            />
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>

                            <Route element={<UserManagementRouting />} path="user-management">
                                <Route element={<Navigate to="/user-management/users" replace />} path="" />
                                <Route
                                    element={
                                        <AccessManagementUserFormProvider>
                                            <AccessManagementUsers disableDeleteFromOptiId />
                                        </AccessManagementUserFormProvider>
                                    }
                                    path="users"
                                />
                                <Route element={<Roles />} path="roles" />
                                <Route element={<AuthorizedPageAccess action={ATTRIBUTE_ROLES.INVITATIONS.READ} />}>
                                    <Route
                                        element={
                                            <AuthorizedPageAccess
                                                action={ATTRIBUTE_ROLES.USERS.READ}
                                                scopedAccess={ACCESS_TYPES.SUPER_ADMIN}
                                            />
                                        }
                                    >
                                        <Route element={<Invitations />} path="invitations" />
                                    </Route>
                                </Route>
                            </Route>

                            <Route path="roles">
                                <Route element={<Roles />} path="" />
                            </Route>

                            <Route
                                path="usage"
                                element={<AuthorizedPageAccess action={ATTRIBUTE_ROLES.BILLING.READ} />}
                            >
                                <Route element={<Usage />} path="" />
                            </Route>

                            <Route element={<SettingsRouting />} path="account-management">
                                <Route element={<AuthorizedPageAccess action={ATTRIBUTE_ROLES.SETTINGS.UPDATE} />}>
                                    <Route element={<Navigate to="/account-management/sso" replace />} path="" />
                                    <Route element={<SSOSetup />} path="sso" />
                                    <Route element={<DomainsPage />} path="domains" />
                                    <Route element={<MFA />} path="mfa" />
                                    <Route element={<LocalLoginSettingsPage />} path="local-login" />
                                </Route>
                            </Route>

                            <Route path="settings" element={<SettingsRouting />}>
                                <Route element={<AuthorizedPageAccess action={ATTRIBUTE_ROLES.SETTINGS.UPDATE} />}>
                                    <Route element={<Navigate to="/settings/authentication/sso" replace />} path="" />
                                    <Route element={<SSOSetup />} path="authentication/sso" />
                                    <Route element={<DomainsPage />} path="authentication/domains" />
                                    <Route element={<MFA />} path="authentication/mfa" />
                                    <Route
                                        element={<AuthorizedPageAccess featureFlag={Flags.ENABLE_GEN_AI} />}
                                        path="genai"
                                    >
                                        <Route element={<GenAIPage />} path="" />
                                    </Route>
                                    <Route element={<LocalLoginSettingsPage />} path="authentication/local-login" />
                                    <Route
                                        element={<Navigate to="/settings/notifications/billing" replace />}
                                        path="notifications"
                                    />
                                    <Route element={<BillingNotificationsPage />} path="notifications/billing" />
                                </Route>
                            </Route>

                            <Route
                                element={<AuthorizedPageAccess featureFlag={Flags.ENABLE_SUPPORT_FEATURES} />}
                                path="support"
                            >
                                <Route element={<Navigate to="/support/access" replace />} path="" />
                                <Route
                                    element={
                                        <FeatureFlagSwitch
                                            featureFlag={Flags.SUPPORT_PAGE_UPDATES}
                                            onElement={<SupportAccess />}
                                            offElement={<Access />}
                                        />
                                    }
                                    path="access"
                                />
                                <Route element={<Usage enableCustomerSelection />} path="usage" />
                                <Route element={<AccountActivation />} path="account-activation" />
                                <Route element={<MasterDashboard />} path="master-dashboard" />
                                <Route element={<UserMigrationPage />} path="user-migration" />
                            </Route>

                            <Route
                                element={<AuthorizedPageAccess action={ATTRIBUTE_ROLES.GROUPS.READ} />}
                                path="groups"
                            >
                                <Route element={<UserGroups />} path="" />
                            </Route>
                        </Route>
                        <Route path="*" element={<NotFound />}></Route>
                    </Routes>
                </UserProvider>
            </ServicesProvider>
            {toast && (
                <Feedback
                    onClose={() => setToast(null)}
                    open
                    type={toast.type || "info"}
                    // @ts-ignore-next-line
                    position="top-right"
                >
                    {toast.message}
                </Feedback>
            )}
        </>
    );
};

export const App = () => {
    const navigate = useNavigate();
    const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
        navigate(toRelativeUrl(originalUri || "/", window.location.origin), { replace: true });
    };

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <SWRConfig
                value={{
                    revalidateIfStale: false,
                    revalidateOnFocus: false,
                    onError: async (error) => {
                        if (error.xhr?.status === 401) {
                            const renewToken = await oktaAuth.token.renewTokens();
                            if (renewToken) {
                                await oktaAuth.tokenManager.setTokens(renewToken);
                            } else {
                                navigate("/logout");
                            }
                        }
                    }
                }}
            >
                <AppRoutesWithContext />
            </SWRConfig>
        </Security>
    );
};

export default App;
