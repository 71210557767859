export const ANALYTICS_EVENT_NAMES = {
    ADD_USER: "Add User",
    CREATE_CUSTOM_GROUP: "Create Custom Group",
    CREATE_CUSTOM_ROLE: "Create Custom Role",
    GEN_AI_UPDATE: "Gen AI Update",
    GRANT_PERMISSIONS: "Grant Permissions",
    REMOVE_USER: "Remove User",
    SESSION_LOGIN: "Authentication: Token Added",
    SESSION_RENEWED: "Authentication: Token Renewed",
    UPDATE_SSO_ORG_LOCAL_LOGIN_SETTING: "Enabled SSO Local Login for Organization",
    UPDATE_SSO_ORG_LOCAL_LOGIN_MFA_SETTING: "Enabled SSO Local Login MFA for Organization",
    UPDATE_LOCAL_LOGIN_FOR_USER: "Enabled SSO Local Login for User"
};

export const ANALYTICS_TRACKED_COMPONENTS = {
    USER_SIDEBAR: "User Sidebar",
    USERS_TAB: "Users Tab",
    INVITATION_SIDEBAR: "Invitation Sidebar",
    UPDATE_GROUP_SIDEBAR: "Update Group Sidebar",
    CREATE_GROUP_SIDEBAR: "Create Group Sidebar",
    CREATE_CUSTOM_ROLE_SIDEBAR: "Create Custom Role Sidebar",
    GEN_AI_TAB: "Gen AI Tab",
    SESSION: "Session",
    SSO_LOCAL_LOGIN_TAB: "SSO Local Login Tab"
} as const;

export const ANALYTICS_FLOWS = {
    USER_PAGE: "User Page",
    PRODUCT_ACCESS: "Product Access",
    GROUP_ACCESS: "Group Access",
    ROLES_PAGE: "Roles Page",
    LOGIN: "Login",
    SETTINGS: "Settings"
} as const;

export const ANALYTICS_PRODUCT_NAME = "Common Platform";
export const ANALYTICS_SUBPRODUCT_NAME = "Admin Center";
