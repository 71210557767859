import { Input, Typography } from "@optimizely/axiom";
import classnames from "classnames";
import React from "react";

export const SupportAccessFormReasonInput = ({
    className,
    error,
    onChange
}: {
    className?: string;
    error?: boolean;
    onChange: ({ value }: { value: string }) => void;
}) => {
    const classes = classnames("flex flex--column", className);
    return (
        <div className={classes}>
            <Typography type="body">
                Reason for Access <span aria-label="(required)" className="oui-label--required" />
            </Typography>
            <Input
                type="text"
                placeholder="Why do you need access?"
                onChange={(e) => onChange({ value: e.target.value })}
            />
            <Typography type="caption">If you have a Zendesk ticket number, please add it here</Typography>
        </div>
    );
};

SupportAccessFormReasonInput.displayName = "SupportAccessFormReasonInput";
